.fetchButton
{
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 30px;
  padding: 15px;
  font-size: 28px;
  box-shadow: 5px 5px 15px rgba(8, 0, 0, 0.917);
  border-radius: 5px;
}
.containerInput
{
  border: 1px solid black;
  box-shadow: 4px 5px 6px rgba(4, 4, 4, 0.8);
}
.containerMDInput
{
  border: 1px solid black;
  box-shadow: 4px 5px 6px rgba(4, 4, 4, 0.8);
  height: 400px; /* Increase the height */
}
.searchInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: 25%; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.limitInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: 5%; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.mdeditor
{
  padding: 2px;
  font-size: 18px;
  width: 99%; /* Increase the width */
  height: 600px; /* Increase the height */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.modelInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: 15%; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.promptDropdownInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: auto; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.promptInput
{
  width: 99%;
  padding: 2px;
  font-size: 16px;
  height:140px; /* Increase the height */
  box-shadow: 4px 4px 8px rgba(4, 4, 4, 0.8);
  margin-bottom: 8px;
}
.outputFormat
{
  width: 99%;
  padding: 2px;
  font-size: 16px;
  box-shadow: 4px 4px 8px rgba(4, 4, 4, 0.8);
  margin-top: 12px;
}
.signonpagebutton {
  background-color: rgb(58, 111, 58);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}

.signuppagebutton {
  background-color: rgb(148, 118, 9);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}
.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.signgooglepagebutton {
  background-color: rgb(31, 115, 211);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}

.signoutbutton {
  background-color: rgb(237, 182, 15);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 16px; /* make the button text extremely small */
}

.signinbutton {
  background-color: rgb(45, 129, 3);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 16px; /* make the button text extremely small */
}

html, body {
  margin-left: 1px;
  margin-right: 1px;
  padding: 0;
  width: 99%;
  overflow-x: hidden;
}

#root {
  width: 99%;
  margin-left: 1px;
  margin-right: 1px;
}

/* src/App.css */

.faEditButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #555;
  font-size: 1.2em;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
}

.popupContent label {
  display: block;
  margin-bottom: 10px;
}

.popupContent input,
.popupContent textarea {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.buttonGroup button {
  padding: 2px 2px;
  cursor: pointer;
}
.flashing {
  animation: flash 1s infinite;
  font-weight: bold;
}

@keyframes flash {
  0% { color: rgb(92, 219, 156); }
  50% { color: rgb(66, 175, 68); }
  100% { color: rgb(0, 17, 5); }
}

form {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 5px; /* Move the text box up 10 pixels */
}
.inputtextbox
{
  padding: 5px;
  font-size: 16px;
  height: 30%; /* Increase the height */
  width: 55%; /* Increase the width */
  margin-left: 5px;
  border: 1px solid black;
}
.addbutton {
  background-color:rgb(16, 130, 16);
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(1, 55, 20, 0.8);
  width: 10%; /* Increase the width */
  height: 48px; /* Increase the height */
}

.signoutbutton {
  background-color: orange;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 14px;
  padding: 5px 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  font-size: 18px; /* make the button text extremely small */
}
.textbutton {
  background-color: grey;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 5px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  font-size: 18px; /* make the button text extremely small */
}

.markcompletebutton {
  background-color: rgb(246, 249, 249);
  border: black;
  border: 2px solid black;
  color: rgb(21, 124, 69);
  cursor: pointer;
  margin-left: 5px;
  padding: 1px 1px;
  box-shadow: 2px 2px 4px rgba(23, 2, 2, 0.953);
  font-size: 18px; /* make the button text extremely small */
}

.donemarkcompletebutton {
  background-color: rgb(6, 88, 28);
  border: black;
  border: 2px solid black;
  color: rgb(252, 254, 253);
  cursor: pointer;
  margin-left: 5px;
  padding: 1px 1px;
  box-shadow: 2px 2px 4px rgba(23, 2, 2, 0.953);
  font-size: 18px; /* make the button text extremely small */
}
.showcompletedbutton {
  background-color: rgb(239, 216, 14);
  border: none;
  color: black;
  cursor: pointer;
  margin-bottom: 110px;
  padding: 10px 10px;
  font-size: 16px; /* make the button text extremely small */
}
.textbutton:hover {
  background-color: #555;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}
.signoutbutton:hover {
  background-color: #555;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}
.textinput {
  padding: 2px;
  font-size: 16px;
  height: 30%; /* Increase the height */
  width: 65%; /* Increase the width */
  margin-left: 5px;
  border: 1px solid #070707;
  box-shadow: 2px 2px 8px rgba(4, 4, 4, 0.8);
}

.signonpagebutton {
  background-color: rgb(58, 111, 58);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}

.signuppagebutton {
  background-color: rgb(148, 118, 9);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}

.signgooglepagebutton {
  background-color: rgb(31, 115, 211);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}
.editForm {
  position: fixed;
  padding: 10px;
  width: 60%;
  bottom: 30%;
  left: 40%;
  transform: translate(-50%, -50%);
  border: 2px solid;
  background-color: whitesmoke;
  box-shadow: 2px 4px 12px rgb(7, 7, 7);
  margin-bottom: 5px; /* Move the text box up 10 pixels */
  font-size: 18px;
}

.button {
  margin-left: 8px;
  padding: 2px 2px;
  border: 1px solid black;
  background-color: #e8ebef;
  color: rgb(3, 22, 57);
  box-shadow: 2px 2px 8px rgba(34, 33, 33, 0.4);
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
}

.button_selected {
  font-size: 16px;
  margin-left: 8px;
  border-radius: 10px;
  padding: 2px 2px;
  border: 2px solid black;
  background-color: rgb(41, 155, 68);
  color: rgb(244, 248, 231);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.button_delete_selected {
  padding: 1px 1px;
  margin-left: 10px;
  background-color: rgb(244, 107, 107);
  color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  cursor: pointer;
  font-size: 18px;
}


.mdxeditor {
    height: 500px !important;
    width: 100%;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}

/* Override MDX Editor's default height */
.mdxeditor [contenteditable="true"] {
    min-height: 400px !important;
}

/* Style the toolbar */
.mdxeditor .toolbar {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    background-color: #f5f5f5;
}

.markdown-body ul {
  list-style-type: disc;
  padding-left: 20px;
}

.markdown-body ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.markdown-body ul ul {
  list-style-type: circle;
}

.homework-container {
    padding: 20px;
    width: 98%; /* Changed from max-width to width */
    margin: 0 auto;
}

.homework-grid {
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    width: 100%; /* Added to ensure full width */
}

.grid-header {
    display: grid;
    grid-template-columns: 70% 15% 15%;  /* Changed to 70/15/15 split */
    background-color: #f5f5f5;
    font-weight: bold;
    border-bottom: 2px solid #ccc;
    width: 100%; /* Added to ensure full width */
}

.grid-row {
    display: grid;
    grid-template-columns: 70% 15% 15%;  /* Changed to 70/15/15 split */
    border-bottom: 1px solid #eee;
    width: 100%; /* Added to ensure full width */
}

.category-col,
.question-col,
.answer-col {
    padding: 12px;
    display: flex;
    align-items: center;
}

.category-col {
    background-color: #f9f9f9;
    font-weight: 500;
}

.answer-col input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.answer-col input:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
}

.grid-row:hover {
    background-color: #f8f8f8;
}

.user-answer-col {
    padding: 12px;
    display: flex;
    align-items: center;
}

.user-answer-col input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.user-answer-col input:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
}

.homework-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.show-answers-button {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pin-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.pin-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.pin-modal-content input {
    margin: 10px 0;
    padding: 8px;
    width: 200px;
}

.pin-modal-content button {
    margin: 5px;
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pin-modal-content button:last-child {
    background-color: #f44336;
}

/* ...existing styles... */

.source-doc-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.source-doc-input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 250px;
    font-size: 14px;
}

.source-doc-input:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 5px rgba(76, 175, 80, 0.3);
}

.fetch-button {
    padding: 8px 16px;
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.fetch-button:hover {
    background-color: #1976D2;
}

/* ...existing styles... */

.correct-answer {
    background-color: #dcf6e2; /* Light green background */
}

@media (max-width: 600px) {
  .grid-row {
      display: flex;
      flex-direction: column;
  }
  .grid-header {
      display: none; /* Hide headers on mobile for better readability */
  }
}

