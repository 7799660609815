.fetchButton
{
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 30px;
  padding: 15px;
  font-size: 28px;
  box-shadow: 5px 5px 15px rgba(8, 0, 0, 0.917);
  border-radius: 5px;
}
.searchInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: 25%; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.limitInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: 5%; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.modelInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: 15%; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.promptDropdownInput
{
  margin-left: 15px;
  padding: 5px;
  font-size: 16px;
  width: auto; /* Increase the width */
  border: 1px solid black;
  box-shadow: 3px 5px 8px rgba(4, 4, 4, 0.8);
}
.promptInput
{
  width: 99%;
  padding: 2px;
  font-size: 16px;
  height:140px; /* Increase the height */
  box-shadow: 2px 4px 3px rgba(4, 4, 4, 0.8);
  margin-bottom: 8px;
}
.promptFullTextInput
{
  width: 99%;
  padding: 2px;
  font-size: 16px;
  height:400px; /* Increase the height */
  box-shadow: 2px 4px 3px rgba(4, 4, 4, 0.8);
  margin-bottom: 8px;
}
.promptTag
{
  width: 49%;
  padding: 2px;
  font-size: 18px;
  box-shadow: 2px 3px 2px rgba(4, 4, 4, 0.8);
  margin-bottom: 4px;
}
.outputFormat
{
  width: 99%;
  box-shadow: 2px 3px 2px rgba(4, 4, 4, 0.8);
  padding: 2px;
  font-size: 16px;
  box-shadow: 4px 4px 8px rgba(4, 4, 4, 0.8);
  margin-top: 12px;
  margin-bottom: 18px;
}
.outputDetailsFormat
{
  margin-top: 18px;
  margin-bottom: 24px;
  box-shadow: 2px 3px 8px rgba(4, 4, 4, 0.8);
}
.responseFormat
{
  border: 1px dotted black;
  padding: 2px;
  background-color: #e4ede8;
}
.signonpagebutton {
  background-color: rgb(58, 111, 58);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 16px; /* make the button text extremely small */
}
.back-button {
  background-color: rgb(21, 122, 21);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 16px; /* make the button text extremely small */
}
.sourceDocumentButton{
  background-color: rgb(196, 217, 244);
  border: none;
  color: #010000;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}
.generateButton
{
  background-color: rgb(24, 68, 161);
  border: 2px solid black;
  color: #fff;
  cursor: pointer;
  margin-left: 24px;
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.8);
  padding: 6px 6px;
  font-size: 24px; /* make the button text extremely small */
}

.signuppagebutton {
  background-color: rgb(148, 118, 9);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}
.practiceButton {
  background-color: rgb(16, 130, 16);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}
.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.signgooglepagebutton {
  background-color: rgb(31, 115, 211);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}

.signoutbutton {
  background-color: rgb(244, 198, 61);
  color: #1c0202;
  margin-left: 20px;
}

.signinbutton {
  background-color: rgb(45, 129, 3);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 16px; /* make the button text extremely small */
}

html, body {
  margin-left: 1px;
  margin-right: 1px;
  padding: 0;
  width: 99%;
  overflow-x: hidden;
}

#root {
  width: 99%;
  margin-left: 1px;
  margin-right: 1px;
}

/* src/App.css */

.faEditButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #555;
  font-size: 1.2em;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}

.popupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
}

.popupContent label {
  display: block;
  margin-bottom: 10px;
}

.popupContent input,
.popupContent textarea {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  box-sizing: border-box;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.buttonGroup button {
  padding: 2px 2px;
  cursor: pointer;
}
.flashing {
  animation: flash 1s infinite;
  font-weight: bold;
}

@keyframes flash {
  0% { color: rgb(92, 219, 156); }
  50% { color: rgb(66, 175, 68); }
  100% { color: rgb(0, 17, 5); }
}

form {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 5px; /* Move the text box up 10 pixels */
}
.inputtextbox
{
  padding: 5px;
  font-size: 16px;
  height: 30%; /* Increase the height */
  width: 55%; /* Increase the width */
  margin-left: 5px;
  border: 1px solid black;
}
.addbutton {
  background-color:rgb(16, 130, 16);
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(1, 55, 20, 0.8);
  width: 10%; /* Increase the width */
  height: 48px; /* Increase the height */
}

.signoutbutton {
  background-color: orange;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 14px;
  padding: 5px 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  font-size: 18px; /* make the button text extremely small */
}
.textbutton {
  background-color: grey;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 5px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  font-size: 18px; /* make the button text extremely small */
}

.markcompletebutton {
  background-color: rgb(246, 249, 249);
  border: black;
  border: 2px solid black;
  color: rgb(21, 124, 69);
  cursor: pointer;
  margin-left: 5px;
  padding: 1px 1px;
  box-shadow: 2px 2px 4px rgba(23, 2, 2, 0.953);
  font-size: 18px; /* make the button text extremely small */
}

.donemarkcompletebutton {
  background-color: rgb(6, 88, 28);
  border: black;
  border: 2px solid black;
  color: rgb(252, 254, 253);
  cursor: pointer;
  margin-left: 5px;
  padding: 1px 1px;
  box-shadow: 2px 2px 4px rgba(23, 2, 2, 0.953);
  font-size: 18px; /* make the button text extremely small */
}
.showcompletedbutton {
  background-color: rgb(239, 216, 14);
  border: none;
  color: black;
  cursor: pointer;
  margin-bottom: 110px;
  padding: 10px 10px;
  font-size: 16px; /* make the button text extremely small */
}
.textbutton:hover {
  background-color: #555;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}
.signoutbutton:hover {
  background-color: #555;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}
.textinput {
  padding: 2px;
  font-size: 16px;
  height: 30%; /* Increase the height */
  width: 65%; /* Increase the width */
  margin-left: 5px;
  border: 1px solid #070707;
  box-shadow: 2px 2px 8px rgba(4, 4, 4, 0.8);
}

.signonpagebutton {
  background-color: rgb(58, 111, 58);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}

.signuppagebutton {
  background-color: rgb(148, 118, 9);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}

.signgooglepagebutton {
  background-color: rgb(31, 115, 211);
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  padding: 5px 5px;
  font-size: 18px; /* make the button text extremely small */
}
.editForm {
  position: fixed;
  padding: 10px;
  width: 60%;
  bottom: 30%;
  left: 40%;
  transform: translate(-50%, -50%);
  border: 2px solid;
  background-color: whitesmoke;
  box-shadow: 2px 4px 12px rgb(7, 7, 7);
  margin-bottom: 5px; /* Move the text box up 10 pixels */
  font-size: 18px;
}

.button {
  margin-left: 8px;
  padding: 2px 2px;
  border: 1px solid black;
  background-color: #eef0f3;
  color: rgb(3, 22, 57);
  box-shadow: 2px 2px 8px rgba(34, 33, 33, 0.4);
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
}

.button:hover {
  box-shadow: 6px 6px 8px rgba(34, 33, 33, 0.4);
  transform: translateX(-1px);
  border: 2px solid black;
}

.button_selected {
  font-size: 16px;
  margin-left: 8px;
  border-radius: 10px;
  padding: 2px 2px;
  border: 2px solid black;
  background-color: rgb(41, 155, 68);
  color: rgb(244, 248, 231);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.button_delete_selected {
  padding: 1px 1px;
  margin-left: 10px;
  background-color: rgb(244, 107, 107);
  color: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  cursor: pointer;
  font-size: 18px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 4px 2px 10px rgba(91, 82, 82, 0.806);
  z-index: 1001;
  width: 92%;
  pointer-events: all;
}

.main-content {
  transition: filter 0.3s;
}

.main-content.dimmed {
  opacity: 0.9;
  pointer-events: none;
}

.reaction-buttons {
  display: flex;
  gap: 8px;
  margin: 8px 0;
}

.reaction-btn {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 2px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background: rgb(237, 233, 233);
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 4px 2px 4px rgba(91, 82, 82, 0.806);
}

.reaction-btn:hover {
  background: #f5f5f5;
  transform: translateY(-1px);
}

.reaction-btn.active {
  background: #9ddfe2;
  border-color: #2196f3;
  color: #010a11;
  font-weight: bold;
}

.App {
  padding: 20px;
}

.grade-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 20px;
}

.grade-item {
  padding: 20px 40px;
  background-color: #f0f0f0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.grade-item:hover {
  background-color: #e0e0e0;
}

.curriculum {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.curriculum ul {
  list-style-type: none;
  padding: 0;
}

.curriculum li {
  margin: 10px 0;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* ...existing code... */

.grades-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.grade-box {
  padding: 20px;
  border-radius: 8px;
  background-color: #f5f8f9;
  border: 2px solid #ddd;
  box-shadow: 2px 2px 2px rgba(153, 139, 139, 0.912);
}

.subjects-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subject-button {
  padding: 5px;
  background-color: #d9e3ed;
  color: rgb(23, 1, 1);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  font-size: 16px; /* make the button text extremely small */
  transition: background-color 0.3s;
}

.previous-button {
  padding: 5px;
  background-color: #215487;
  color: rgb(235, 239, 246);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8);
  font-size: 16px; /* make the button text extremely small */
  transition: background-color 0.3s;
}
.subject-button:hover {
  background-color: #628bb6;
}

.subject-content {
  margin: 0 auto;
  padding: 5px;
}

.generated-content {
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 8px;
  margin: 5px 0;
  white-space: pre-wrap;
  text-align: left;
}

/* ...existing code... */

.topics-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px;
  font:100%/2 Arial, sans-serif;
  font-size: 16px;
}

.topic-box {
  border: 2px solid #666;
  padding: 5px;
  border-radius: 8px;
  background-color: #f8f8f8;
  font:100%/2 Arial, sans-serif;
  font-size: 18px;
}

.topic-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.topic-content {
  font-size: 16px;
  line-height: 1.5;
  font:100%/2 Arial, sans-serif;
  font-size: 18px;
}
.topic-item {
  padding: 2px;
  border-radius: 4px;
  margin: 2px 0;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 2px rgba(167, 154, 154, 0.912);
  font-size: 16px;
}
.topic-item-running {
  padding: 1px;
  border-radius: 1px;
  margin: 2px 0;
  border: 3px solid #110101;
  box-shadow: 6px 6px 6px rgba(23, 0, 0, 0.912);
  font-size: 16px;
  background-color: blanchedalmond;
}


/* ...existing styles... */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
}

.user-controls {
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
}

.user-info span {
  font-size: 16px;
  color: #666;
}

.data-source-select {
  font-size: 16px;
  padding: 8px 12px;
  border: 2px solid #ddd;
  border-radius: 6px;
  background-color: #f8f9fa;
  color: #333;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.data-source-select:hover {
  border-color: #007bff;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}

.data-source-select:focus {
  outline: none;
  border-color: #0056b3;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
/* ...rest of existing styles... */

.grade-button {
  padding: 5px 5px;
  margin: 10px;
  font-size: 18px;
  border: 3px solid #062032;
  border-radius: 5px;
  background-color: white;
  box-shadow: 6px 6px 6px rgba(112, 136, 167, 0.912);
  color: #154461;
  cursor: pointer;
  transition: all 0.3s ease;
}

.grade-button:hover {
  background-color: #3498db;
  color: white;
}

.grade-content {
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}