  
  .signuppagebutton {
    background-color: rgb(148, 118, 9);
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
    padding: 5px 5px;
    font-size: 18px; /* make the button text extremely small */
  }
  
  .signgooglepagebutton {
    background-color: rgb(31, 115, 211);
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
    padding: 5px 5px;
    font-size: 18px; /* make the button text extremely small */
  }
  .editForm {
    position: fixed;
    padding: 10px;
    width: 60%;
    bottom: 30%;
    left: 40%;
    transform: translate(-50%, -50%);
    border: 2px solid;
    background-color: whitesmoke;
    box-shadow: 2px 4px 12px rgb(7, 7, 7);
    margin-bottom: 5px; /* Move the text box up 10 pixels */
    font-size: 18px;
  }
  
  .signoutbutton {
    background-color: orange;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-left: 24px;
    padding: 5px 5px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
    font-size: 18px; /* make the button text extremely small */
  }